@import "colors";

.header{
    background-image: url('../assets/3.png');;
    width: auto;
    height: 100svh;
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;
    padding: 2.5rem;

}

.page{
    align-items: center;
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 3rem;
}

.main{
   width: 100%;
   display: block;
   background-color: $first;
}

.container-full{
    width: 100%;
    height: 100%;
    border: 0px solid var(--box-border);
    background-image: linear-gradient(118deg, #1c1c21, #131316);
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.container_logo{
    z-index: 3;
    width: 11rem;
    border: 2px solid var(--box-border);
    backdrop-filter: blur(48px);
    object-fit: fill;
    background-color: #232328;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0.6rem 1.125rem;
    display: flex;
    position: absolute;
    top: 1rem;
    left: 0%;
    right: 0%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .2);
}


.container_content{
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 2rem;
}

.bg_logo{
    float: left;
    color: #333;
    text-decoration: none;
    position: relative;
}

.size_bg{
    width: 8.125rem;
    height: 4.25rem;;
}

.text-color{
    font-size: 3rem;
    max-width: 30ch;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
}

.expand{
    font-size: 3rem;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient( 69.9deg, rgba(179,19,19,1) 20.6%, rgba(236,101,101,0.76) 78.3% );
    -webkit-background-clip: text;
}

.para{
    width: 42ch;
    color: white;
    font-family: Outfit, sans-serif;
    font-size: 1.5rem;
    line-height: 1.3;
    text-align: center;
}

.container_boxes{
    grid-column-gap: 2.5rem;
    display: flex;
    color: white;
}

.container_tick{
    grid-column-gap: 0.5rem;
    grid-row-gap: 0rem;
    align-items: center;
    display: flex;
}

.FAQS {
    padding: 0 2.5rem 7.5rem;
}

.container-large{
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100%;
    max-width: none;
}

.padding-section-large{
    padding-top: 2rem;
    padding-bottom: 0;
}

.box-faq{
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border-radius: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    padding: 3rem 3.5rem;
    display: grid;
    background-image: linear-gradient(118deg, #1c1c21, #131316);
    border-radius: 0;
}

.container_faq-right{
    width: 100%;
    height: 100%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 0;
    display: grid;
}

.faq-wrap{
    width: 100%;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid rgba(255, 255, 255, .12);
    border-radius: 0;
    padding: 20px 0;
}

.faq-wrap-2_questions{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.question{
    width: 90%;
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
}

.plus-wrap{
    width: 19px;
    height: 19px;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.plus-horizontal{
    width: 100%;
    height: 3px;
    background-color: #edebef;
    border-radius: 100px;
}

.vertical{
    width: 3px;
    height: 100%;
    background-color: #edebef;
    border-radius: 100px;
    position: absolute;
}

.faq-wrap-2_answers{
    height: 0px; 
    display: none;
    width: 100%;
}

.answer{
    opacity: 0;
    width: 100%;
    max-width: 70ch;
    min-width: 100%;
    color: #aeacb0;
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    font-size: 1.125rem;
    font-weight: 400;
}

.space-medium{
    width: 100%;
    padding-top: 2rem;
}

.padding-none{

}

.container-large{
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100%;
    max-width: none;
}

.padding-section-large{
    padding-bottom: 0;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.box-faq{
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border-radius: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    padding: 3rem 3.5rem;
    display: grid;
    background-image: linear-gradient(118deg, #1c1c21, #131316);
}

.box-footer{
    border-radius: 40px;
    justify-content: space-between;
    padding: 1.5rem 3.5rem;
    display: flex;
    background-image: linear-gradient(118deg, #1c1c21, #131316);
}

.container_footer-right{
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.container_footer-left{
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.pricing{
    background-image: url('../assets/3.png');
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;
}

.padding-global{
    padding-left: 5%;
    padding-right: 5%;
}

.container-large{
    width: 100%;
    max-width: none;
}

.padding-section-large{
    padding-top: 7rem;
    padding-bottom: 7rem
}

.container-label{
    justify-content: center;
    align-items: center;
    display: flex;

}

.label{
    color: #9f77f9;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient( 69.9deg, rgba(179,19,19,1) 20.6%, rgba(236,101,101,0.76) 78.3% );
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Outfit, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
}

.spacer-small{
    width: 100%;
    padding-top: 0.5rem
}

.spacer-huge{
    width: 100%;
    padding-top: 6rem;
}

.w-layout{
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 100%;
}

.w-layout-cell{
    grid-row: span 2 / span 2;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    background-image: linear-gradient(118deg, #1c1c21, #131316);
}

.container_how-it-works{
    max-width: 75rem;
    // grid-column-gap: 4rem; 
    grid-row-gap: 4rem; 
    /* grid-template-rows: auto auto auto; */
    /* grid-template-columns: 1fr 1fr; */
    grid-auto-columns: 1fr;
    margin-left: auto;
    margin-right: auto; 
    /* padding-left: 2rem; */
    /* padding-right: 2rem; */
    display: grid;
    text-align: center;
    justify-content: center;
}

.box{
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: auto;
}

.label-mini{
    justify-content: flex-start;
    align-items: center;
    color: #D72323;
}

.subheading_text{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: Outfit, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.3;
    display: block;
    color:#edebef
}

.spacer-medium{
    width: 100%;
    padding-top: 2rem;
}

.container_benefit-boxes{
    max-width: 75rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

.box-benefits{
    height: 26.25rem;
    border-radius: 40px;
    flex-direction: column;
    padding: 2rem 1.5rem;
    display: flex;  
    background-image: linear-gradient(118deg, #1c1c21, #131316);
}

.container_work-categories{
    max-width: 60rem;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.padding_section_large{
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.content_work-categories{
    background-image: linear-gradient(118deg, #1c1c21, #131316);
    border-radius: 16px;
    padding: 1rem 2rem;
}

.text-color-wc{
    color: white;
    display: block;
    font-weight: 500;
}

.text-size-medium{
    color: white;
    font-size: 1.25rem;
}

.container_how-it-works-text{
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
    justify-self: center;
}

.text-align{
    color: white;
    
}

.conatiner_what-we-do-categories{
    max-width: 75rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

.we_do_box{
    height: 35rem;
    border-radius: 40px;
    flex-direction: column;
    padding: 2rem;
    display: flex;
    overflow: hidden;
    background-image: linear-gradient(118deg, #1c1c21, #131316);
}

.we_do_another-box{
    padding-top: 2rem;
    height: 35rem;
    border-radius: 40px;
    flex-direction: column;
    padding: 2rem;
    display: flex;
    overflow: hidden;
    background-image: linear-gradient(118deg, #1c1c21, #131316);
}

.container_large{
    padding-left: 14%;
    padding-right: 14%;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100%;
    max-width: none;
}

.container_medium{
    max-width: 72rem;
    grid-row-gap: 0rem;
    border-radius: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 9rem;
    padding-bottom: 9rem;
    display: flex;
    background-image: linear-gradient(118deg, #1c1c21, #131316);

}

.container_arrow{
    grid-row-gap: 22.5rem;
    flex-direction: column;
    margin-top: 11rem;
    display: flex;
}

.container_floating_h2{

}

.label{
    color: #9f77f9;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    background-image:linear-gradient( 69.9deg, rgba(179,19,19,1) 20.6%, rgba(236,101,101,0.76) 78.3% );
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Outfit, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
}

.text-color-price{
    color: white;
    display: block;
    font-weight: 600;
}

.contact_pricing{
    cursor: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-price{
    color: white;
    font-size: 2rem;
    font-weight: 600;
}

.spacer-large{
    width: 100%;
    padding-top: 3rem;
}

.button{
    width: 100%;
    height: 3.5rem;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient( 69.9deg, rgba(179,19,19,1) 20.6%, rgba(236,101,101,0.76) 78.3% );
    border: 0 solid rgba(0, 0, 0, 0);
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 2rem;
    font-family: Outfit, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    display: flex;
    box-shadow: 0 8px 45px rgba(169, 122, 248, .4);
    cursor: pointer;
}

.text-only{
    text-align: center;
    color: gray;
    font-size: 1.125rem;
}

.text-color-size{
    line-height: 1.3;
    color: gray;
    text-align: center;
    font-size: 1.25rem;
}

.text-color-head{
    font-size: 2rem;
    max-width: 30ch;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center
}

.text-color-we-do{
    font-size: 2rem;
    max-width: 30ch;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
}

.contact-modal{
    opacity: 90;
    // display: none;
    z-index: 99;
    justify-content: center;
    align-items: center;
    // display: none;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.modal_background{
    cursor: pointer;
    background-color: rgba(0, 0, 0, .72);
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.container_modal{
    width: 56rem;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
    display: flex;
    position: static;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.container_x{
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 0.25rem;
    display: flex;
    position: static;
}

.inline-form{
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.plus-wrap-c{
    width: 36px;
    height: 36px;
    background-color: darkslategray;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    transform: rotate(45deg);
}

.plus-horizontal-c{
    width: 55%;
    height: 3px;
    background-color: #edebef;
    border-radius: 100px;
}

.plus-vertical-c{
    width: 3px;
    height: 55%;
    background-color: #edebef;
    border-radius: 100px;
    position: absolute;
}

.html{
    width: 95%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.form{
    max-height: inherit;
    height: inherit;
    min-height: inherit;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
}

.contact-modal-bg{
    opacity: 90%;
    width: 100vw;
    height: 100vh;
    background-color: rgb(121, 109, 109);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-container{
    opacity: 100%;
    width: 600px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.contact-form {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
  }

  label {
    display: flex;
    margin-bottom: 2px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: white;
  }

.container_benefit-boxes{
    @media screen and (max-width: 1262px){
      grid-template-columns: 1fr 1fr;
} 
    @media screen and (max-width: 839px){
    grid-template-columns: 1fr;
}
   @media screen and (max-width: 440px){
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    grid-row-gap: 2rem;
}

}

.padding-section-large{
@media screen and (max-width: 479px)
 {
    padding-top: 2rem;
}

@media screen and (max-width: 767px){
    padding-top: 2rem;
}
}

.box-faq{
    @media screen and (max-width: 767px)
 {
    grid-template-columns: 1fr;
}
    @media screen and (max-width: 420px)
   {
   display: flex;
   flex-direction: column;
   padding: 0rem 1.5rem;
  }
}

.container_logo{
    @media screen and (max-width: 780px){
     left: 0%;
     right:0%;
     top: 0rem;
} 
    @media screen and (max-width: 479px){
    grid-template-columns: 1fr;
}
}

.header{
@media screen and (max-width: 479px)
{
    height: 80vh;
}

@media screen and (max-width: 767px)
{
    width: 100vw;
    height: 100vh;
    margin-top: 0rem;
    padding: 1rem;
}
@media screen and (max-width: 991px)
 {
    height: 100vh;
}
}

.navbar{
    @media screen and (max-width: 564px)
 {
    
}
}

.container_content{
    @media screen and (max-width: 564px)
 {
    
}
}

.text-color{
    @media screen and (max-width: 564px)
    {
       font-size: 1.8rem;
       max-width: 25ch;
   }
   @media screen and (max-width: 320px)
   {
      font-size: 1.2rem;
      max-width: 25ch;
  }
}

.para{
@media screen and (max-width: 610px)
    {
      width: 30ch;

   }
   @media screen and (max-width: 460px)
    {
      width: 30ch;
      font-size: 1rem;
   }
}

.container_boxes{
@media screen and (max-width: 460px)
    {
       max-width: 25ch;
       flex-direction: column;
   }
}

.container_logo{
    @media screen and (max-width: 460px)
        {
           max-width: 25ch;
       }
}

.span{
    @media screen and (max-width: 460px)
    {
       font-size: 1.5rem;
   }
}

.expand{
    @media screen and (max-width: 420px)
    {
       font-size: 1.8rem;
   }
}

.box-benefits{
    @media screen and (max-width: 420px)
    {
        padding: 0rem 0rem; 
   }
}

.w-layout{
    @media screen and (max-width: 420px)
    {
        padding: 0rem 0rem;
        display: flex;
        flex-direction: column;
   }
}

.box-footer{
    @media screen and (max-width: 420px)
    {
        border-radius: 36px;
        justify-content: space-between;
        padding: 1.5rem 1.5rem;
        display: flex;
        flex-direction: column-reverse;
        background-image: linear-gradient(118deg, #1c1c21, #131316);
   }
}

.container_footer-right{
    @media screen and (max-width: 420px)
    {
        grid-column-gap: 0.5rem;
        grid-row-gap: 2.5rem;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
   }
}

.container_footer-left{
    @media screen and (max-width: 420px)
    {
        grid-column-gap: 0.5rem;
        grid-row-gap: 2.5rem;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
   } 
}


