.navbar{
    height: 4.5rem;
    border: 2px solid var(--box-border);
    backdrop-filter: blur(48px);
    background-color: rgba(28, 28, 33, .8);
    background-image: linear-gradient(168deg, rgba(255, 255, 255, .04), rgba(255, 255, 255, 0));
    border-radius: 100px;
    padding: 0;
    display: flex;
    position: fixed;
    bottom: 1rem;
}
.page{
    background-color: black;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}
.container{
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    align-items: center;
    display: flex;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.top_arrow{
    width: 88px;
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.arrow{
    background-color: rgba(237, 235, 239, 0);
}

.nav-menu{
    grid-column-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0;
    display: flex;
    float: right;
    position: relative;
}

.nav-menu-link{
    color: white;
    padding: 12px 18px;
    font-family: Outfit, sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3;
    vertical-align: top;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.Pricing{
    height: 3.5rem;
    color: white;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient( 69.9deg, rgba(179,19,19,1) 20.6%, rgba(236,101,101,0.76) 78.3% );
    border: 0 solid rgba(0, 0, 0, 0);
    border-radius: 1000px;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;
    font-family: Outfit, sans-serif;
    font-weight: 700;
    line-height: 1.3;
    display: flex;
    text-decoration: none;
}

.container{
    @media screen and (max-width: 420px)
    {
     /*grid-column-gap: 0.5rem; */
     grid-row-gap: 0.5rem; 
     align-items: center;
     display: flex;
     max-width: 30rem;
     /* margin-left: auto; */
     /* margin-right: auto; */
    } 
 }
 
 .top_arrow {
     @media screen and (max-width: 450px){
         width: 40px;
         border-radius: 1000px;
         justify-content: center;
         align-items: center;
         display: flex;
     }
 }
     
 .nav-menu {
     @media screen and (max-width: 450px){
         grid-column-gap: -0.75rem;
         grid-row-gap: 0.25rem;
         align-items: center;
         padding: 0.5rem 0.5rem 0.5rem 0;
         display: flex;
         float: right;
         position: relative;
     }
 }
 
 .nav-menu-link {
     @media screen and (max-width: 420px){
         color: white;
         padding: 1px 1px;
         font-family: Outfit, sans-serif;
         /* font-size: 1.125rem; */
         /* font-weight: 500; */
         /* line-height: 1.3; */
         vertical-align: top;
         text-align: left;
         margin-left: auto;
         margin-right: auto;
        //   padding: 2px; 
         text-decoration: none;
         display: inline-block;
         position: relative;
     }
 }
 
 .Pricing {
     @media screen and (max-width: 450px){
         /* height: 3.5rem; */
         color: white;
         text-align: center;
         background-color: rgba(255, 255, 255, 0);
         background-image: linear-gradient(69.9deg, rgb(179, 19, 19) 20.6%, rgba(236, 101, 101, 0.76) 78.3%);
         border: 0 solid rgba(0, 0, 0, 0);
         border-radius: 1000px;
         justify-content: center;
         align-items: center;
         padding: 0.5rem 0.25rem;
         font-family: Outfit, sans-serif;
         /* font-weight: 700; */
         line-height: 1.3;
         display: flex;
         text-decoration: none;
     }
 }

 .conatiner_what-we-do-categories{
    @media screen and (max-width: 450px){
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr;
    }
 }

.container_large{
    @media screen and (max-width: 450px){
    padding-left: 0%;
    padding-right: 0%;
    }
}